@charset "UTF-8";

/* ANIMATION -----
----------------------- */

*{
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Hind Vadodara", sans-serif;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    display: block;
  }
}

.fade-in {
  animation: fadeIn 1s;
}

/* END ANIMATION -----
------------------------- */


/* CLASS COLOR -----
------------------------- */
.theme-color-a {
  color: #0A4A78;
}

.theme-color-b {
  color: #0A4A78;
}

.theme-color-c {
  color: #0A4A78;
}

.theme-color-d {
  color: #38B6FF;
}

.theme-color-e {
  color: #0A4A78;
}

.theme-color-f {
  color: #26ABE0;
}

.typo-color-a {
  color: #0A4A78;
}

.typo-color-b {
  color: #485768;
}

.typo-color-c {
  color: #69798d;
}

/* END CLASS COLOR -----
------------------------------ */


/* CLASS BACKGROUND-COLOR -----
------------------------------------- */
.theme-bg-a {
  background-color: #0A4A78;
}

.theme-bg-b {
  background-color: #0A4A78;
}

.theme-bg-c {
  background-color: #0A4A78;
}

.theme-bg-d {
  background-color: #38B6FF;
}

.theme-bg-e {
  background-color: #0A4A78;
}

.theme-bg-f {
  background-color: #26ABE0;
}

.typo-bg-a {
  background-color: #0A4A78;
}

.typo-bg-b {
  background-color: #485768;
}

.typo-bg-c {
  background-color: #69798d;
}

.grad-style-ab,
.case-study .video-play-btn{
  background-image: linear-gradient(45deg, #04266e, #0769be);
  background-repeat: repeat-x;
}
.inner-page::before {
  background-image: url("../body1.jpeg");
  background-repeat: no-repeat;
  background-size: cover;

}

.bg-style-a1.inner-page::before {
  background-image: linear-gradient(45deg, #e9a17b, #ff7cb0);
  background-repeat: repeat-x;
}

.grad-style-cd,
.pricing-block:hover .icon-container::after,

.featured-item::before,
.body-bg-style-2.inner-page::before,
.banner::after,
.navbar-toggler span {
  background-image: linear-gradient(45deg, #38B6FF, #0A4A78);
  background-repeat: repeat-x;
}

.grad-style-ef,
.small-agency-case-study::after,
.body-bg-style-3.inner-page::before,
.icon-container.grad-style-ef-light::after {
  background-image: linear-gradient(45deg, #26ABE0, #0A4A78);
  background-repeat: repeat-x;
}

.grad-style-ab-light {
  background-image: linear-gradient(45deg, #f1f8ff, #f1efff);
  background-repeat: repeat-x;
}

.grad-style-cd-light {
  background-repeat:no-repeat;
  background-size: cover;
}

.grad-style-ef-light {
  background-image: linear-gradient(45deg, #eff1ff, #f3f4ff);
  background-repeat: repeat-x;
}

.txt-grad-ab {
  background: linear-gradient(45deg, #58DDFF, #0A4A78);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.txt-grad-cd {
  background: linear-gradient(45deg, #0A4A78, #0A4A78);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.item-b {
  margin-left: 100px;
}

.txt-grad-ef,
.filter-button:hover,
.filter-button.is-checked,
.nav-tabs .visited .nav-link i,
.nav-tabs .visited .nav-link span,
.nav-tabs .visited .nav-link::after,
.nav-tabs .nav-item:not(:last-child) .nav-link:hover::after,
.nav-tabs .nav-item:not(:last-child) .nav-link.active::after,
.nav-tabs .nav-link:hover i,
.nav-tabs .nav-link:hover span,
.nav-tabs .nav-link.active i,
.nav-tabs .nav-link.active span,
.common-list-items li i {
  background: linear-gradient(45deg, #26ABE0, #0A4A78);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.ml-auto {
  margin-left: auto !important;
}

@media only screen and (min-width: 768px) {
  .ml-md-auto {
    margin-left: auto !important;
  }
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

@media only screen and (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }
}

@media only screen and (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }
}
.small-agency-case-study::before,
.small-agency-case-study::after{

}
/* END CLASS BACKGROUND-COLOR -----
----------------------------------------- */

/* COMMON CSS -----
---------------------- */
body {
  overflow-x: hidden;
  
}

.container {
  max-width: 1350px;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.row>[class*=col] {
  padding-left: 15px;
  padding-right: 15px;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

.navbar-toggler:focus {
  box-shadow: none;
}

section {
  position: relative;
}

.section-padding {
  padding: 5rem 0 3.5rem;
}

.grey-bg {
  background-color: #0A4A78;
}

.grey-bg h1,
.grey-bg h2,
.grey-bg h3,
.grey-bg h4,
.grey-bg h5,
.grey-bg h6,
.grey-bg p {
  color: #fff;
}

.light-grey-bg {
  background-color: #fcfdff;
}

a,
button,
img,
a:focus,
button:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.icon-container {
  width: 135px;
  height: 110px;
  border-radius: 110px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: rotate(150deg);
  margin-bottom: 4.5rem;
  position: relative;
}

.icon-container i {
  font-size: 5rem;
  line-height: 1;
  font-style: normal;
  transform: rotate(-150deg);
  transition: all 0.5s;
}

.icon-container::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 110px;
  z-index: -1;
  opacity: 0;
  transition: all 0.5s;
}

.legal {
  border-radius: 5px;
  border: none;
  background-color: #fff;
  padding: 30px 20px;
}

.legal>* {
  color: black !important;
  z-index: 10;
}

.legal-info {
  float: right;
  font-weight: bold;
  white-space: nowrap;
  color: inherit;
  padding: 0;
  background-image: linear-gradient(120deg, #26abe08c 0%, #26abe08c 100%);
  background-repeat: no-repeat;
  background-size: 100% .3em;
  background-position: 0 80%;
  position: relative;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.legal ul {
  padding-left: 45px;
  list-style: outside;
}

.legal>* .bold {
  font-weight: bold;
}

.main-inf {
  text-align: left;
  margin-top: 2rem;
}

.align-left {
  text-align: left;
}

.disclaimer-inf {
  text-align: center;
  color: #FF1C1C;
  font-weight: bold;
}

.slick-track {
  display: flex;
  flex-wrap: wrap;
}

.slick-dots {
  display: none !important;
}

.slick-arrow {
  width: 60px;
  height: 30px;
  display: block;
  top: auto;
  bottom: -78px;
  background-color: red;
  transition: all 0.3s;
}

.slick-prev {
  background: url("https://cdn.kesency.com/images/icon/slick-arrow-left.png") center center no-repeat;
  left: auto;
  right: 60px;
}

.slick-next {
  background: url("https://cdn.kesency.com/images/icon/slick-arrow-right.png") center center no-repeat;
  left: auto;
  right: 0;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.slick-prev:hover,
.slick-prev:focus {
  background: url("https://cdn.kesency.com/images/icon/slick-arrow-left.png") center center no-repeat;
}

.slick-next:hover,
.slick-next:focus {
  background: url("https://cdn.kesency.com/images/icon/slick-arrow-right.png") center center no-repeat;
}

.slick-prev:hover,
.slick-next:hover {
  opacity: 0.5;
}

.modal {
  z-index: 99999999;
  background: #ffffff;
  padding: 60px 15px !important;
  background-image: url("../back0.jpg");
}

.modal-header {
  border: none;
  padding: 0;
}

.modal-dialog {
  width: 100%;
  max-width: 1230px;
}

.modal .modal-content {
  padding: 75px 75px 40px;
  border-radius: 10px;
  overflow: hidden;
  border: none;
  z-index: 1;
}

.modal .modal-content h3 {
  margin-bottom: 15px;
  color: #000000;
}

.modal .modal-content h3 span {
  display: block;
  font-family: "Hind Vadodara", sans-serif;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #69798d;
  font-weight: 300;
  margin-bottom: 4px;
}

.modal .modal-content h4 span {
  display: block;
  font-family: "Hind Vadodara", sans-serif;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #69798d;
  font-weight: 300;
  margin-bottom: 4px;
}

.modal .modal-content p:last-child {
  margin-bottom: 10px;
}

.get-a-quote-modal .modal-content {
  background-color: white;
  padding: 20px 0;
}

.get-a-quote-modal .contact-wrapper.contact-page-form-wrapper,
.get-a-quote-modal .contact-info.floating-contact-info {
  margin-bottom: 0;
}

.get-a-quote-modal .contact-wrapper h3 {
  margin-bottom: 3.2rem;
}

a:hover {
  color: #7cc4ff;
}

.modal.show .modal-dialog {
  margin-left: auto;
  margin-right: auto;
}

.modal button.close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 1.6rem;
  background-color: transparent;
}

.modal button.close span {
  display: none;
}

.get-a-quote-modal button.close {
  right: 9rem;
  z-index: 9;
}

.modal-body {
  padding: 0;
}

.modal-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.6;
  transform: translate(-50%, -50%);
}

.modal-feat-img {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3.8rem;
  border-radius: 10px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 4rem;
  height: 4rem;
}

.carousel-control-next,
.carousel-control-prev {
  transition: all 0.5s;
}

.carousel-indicators [data-bs-target] {
  width: 8px;
  height: 8px;
  background: #0A4A78;
  border-radius: 50%;
  opacity: 0.5;
  transition: all 0.5s;
  cursor: pointer;
  border-top: none;
  border-bottom: none;
}

.carousel-indicators [data-bs-target].active {
  background: #0A4A78;
  opacity: 1;
}

/* END COMMON CSS -----
---------------------- */


/* POP UP -----
---------------------- */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.popup .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #FF1C1C;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 8px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(141, 49, 49) transparent transparent transparent;
}

.popup .show-popup {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}

/* END POP UP -----
---------------------- */


/* TYPOGRAPHY -----
---------------------- */
html {
  font-family: "Hind Vadodara", sans-serif;
  font-size: 10px;
}

body,
p {
  font-family: "Hind Vadodara", sans-serif;
  font-size: 1.8rem;
  line-height: 3rem;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h6 {
  font-family: "Hind Vadodara", sans-serif;
  color: #ffffff;
  line-height: 1.4;
}
h5{
  font-family: "Hind Vadodara", sans-serif;
  color: #070707;
  line-height: 1.4;

}

h1 {
  font-size: 4rem;
  font-weight: 700;
}

h2 {
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 2.1rem;
}

h3 {
  font-size: 2.8rem;
  font-weight: 500;
}

h4 {
  font-size: 2.6rem;
  font-weight: 500;
}

h5 {
  font-size: 2.4rem;
  font-weight: 700;
}

h6 {
  font-size: 1.8rem;
  font-weight: 700;
}

.section-subheading {
  font-size: 2.2rem;
  line-height: 4rem;
  font-weight:normal;
  color: #000000;
}


.section-subheading1 {
  font-size: 2.4rem;
  line-height: 4rem;
  font-weight:normal;
  color: #ffffff;
}

.small-heading {
  font-size: 3.6rem;
}

.content-block__sub-title {
  display: block;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #69798d;
  font-weight: 300;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1.9rem;
}

/* END TYPOGRAPHY -----
---------------------- */


/* BUTTON CSS -----
---------------------- */
.custom-btn {
  max-width: 100%;
  display: inline-block;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  transition: all 0.5s;
}

.navbar-nav .nav-link.custom-btn {
  padding-left: 3rem;
  padding-right: 3rem;
}

.btn-big {
  font-size: 1.6rem;
  line-height: 30px;
  padding: 1.6rem 3rem;
}

.btn-mid {
  font-size: 1.4rem;
  line-height: 30px;
  padding: 1rem 3rem;
}

.btn-small {
  font-size: 1.3rem;
  line-height: 30px;
  padding: 0.5rem 2.5rem;
}

.btn-full {
  width: 100%;
  max-width: 39rem;
}

.custom-btn:hover,
.custom-btn.active {
  text-decoration: none;
  color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.video-play-btn {
  width: 100px;
  height: 100px;
  display: inline-block;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  line-height: 100px;
  text-align: center;
  padding-left: 7px;
  color: #6245fe;
  transition: all 0.5s;
  outline: none;
}

#button-contact-m {
  position: relative;
  margin-bottom: 30px;
}

#button-quote {
  padding-top: 15px;
}

.video-play-btn:hover {
  color: #6245fe;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.1);
}

.video-play-btn svg {
  margin-right: 5px;
}

.text-only-btn {
  display: inline-block;
  color: #6245fe;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.5s;
  margin-bottom: 0.7rem;
}

.text-only-btn i {
  vertical-align: middle;
  margin-left: 0.5rem;
}

.text-only-btn:hover {
  text-decoration: none;
}

/* END BUTTON CSS -----
---------------------- */

/* ACCOUNT --------
----------------------- */

section[id="form"] .container form {
  max-width: 95rem;
  margin: 0 auto;
  padding: 3rem;
  border-radius: 5px;
  background-color:#fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

section[id="form"] .container form div div input {
  margin-bottom: 3rem;
}

/* END ACCOUNT -----
---------------------- */


/* FORM CSS -----
---------------------- */
::placeholder {
  color: #69798d !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #69798d !important;
  opacity: 1;
}

::-ms-input-placeholder {
  color: #69798d !important;
  opacity: 1;
}

input,
textarea {
  width: 100%;
  font-size: 1.8rem;
  line-height: 3rem;
  padding: 1.5rem 2rem;
  color: #69798d;
  background: transparent;
  border: 2px solid #f1f0ff;
  border-radius: 5px;
  transition: all 0.5s;
}

input:hover,
input:focus,
textarea:hover,
textarea:focus {
  outline: none;
  border-color: #0A4A78;
}

/* END FORM CSS -----
---------------------- */

/* HEADER ------
--------------------- */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 15px;
  background-color: white;
  z-index: 99998;
  transition: all 0.5s;
}

.navbar.scrolled {
  background: #fff;
  z-index: 999;
  padding: 15px;
  box-shadow: 0 0 10px rgba(166, 209, 237, 0.3);
}

.scrolled img {
  width: 80%;
}

.navbar.scrolled .navbar-nav .custom-dropdown-menu {
  top: 125%;
}

.navbar-nav {
  flex-wrap: wrap;
}

.navbar-nav .nav-item {
  margin-left: 3rem;
}

.navbar-nav .nav-item .nav-link:not(.custom-btn) {
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: black;
  margin: 0;
  padding: 15px 0;
  text-transform: uppercase;
  transition: all 0.3s;
  font-weight: 500;
}

.navbar-nav .nav-item .nav-link:not(.custom-btn):hover,
.navbar-nav .nav-item .nav-link:not(.custom-btn).active {
  color: #0A4A78;
}

.navbar-nav .nav-item .nav-link {
  font-family: "Hind Vadodara", sans-serif;
}

.navbar-brand {
  padding: 0;
  height: 50px;
  
}

.navbar-brand img {
  transition: all 0.5s;
  width:180px ; 
  height:50px;
  

  
  
}

.navbar.solid-bg {
  background: #fff;
  box-shadow: 0 0 10px rgba(166, 209, 237, 0.3);
}

.navbar-toggler {
  border: none;
  outline: none;
  padding: 0;
}

.navbar-toggler span {
  width: 3rem;
  height: 0.3rem;
  border-radius: 20px;
  display: block;
  margin: 0.6rem 0;
}

.navbar-toggler span.navbar-toggler-icon {
  display: none;
}

.navbar-toggler:focus {
  outline: none;
}

.navbar button .custom-toggler-icon {
  transition: all 0.5s;
}

.navbar button.change .bar1 {
  transform: rotate(-45deg) translate(-7px, 5px);
}

.navbar button.change .bar2 {
  opacity: 0;
}

.navbar button.change .bar3 {
  transform: rotate(45deg) translate(-8px, -6px);
}

.navbar .dropdown>a::after {
  content: "";
  font-family: "Hind Vadodara", sans-serif;
  font-size: 12px;
  margin-left: 2px;
}

.navbar-nav .custom-dropdown-menu {
  display: none;
  position: absolute;
  left: 0;
  top: 152%;
  background-color: #fff;
  border: none;
  padding: 15px 0;
  border-radius: 0 0 5px 5px;
  border-top: 2px solid #0057ff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition-timing-function: linear !important;
}

.dropdown-item {
  font-family: "Hind Vadodara", sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #69798d;
  padding: 5px 25px;
  transition: all 0.5s;
}

.dropdown-item:hover,
.dropdown-item.active {
  background-color: transparent;
  color: #0A4A78;
}

/* END HEADER ------
--------------------- */


/* BANNER ------
--------------------- */
.banner {
  position: relative;
  transition: all .5s ease;
  height: 48rem;
 background-image: url('../background-cover.svg'), url('../mask.png'),
  url('../background.svg');
  background-position: right -500px bottom 0, left, center;
  background-size: cover, cover, cover;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-blend-mode: normal, color-dodge, normal;
  
}

.banner::before,
.banner::after {
  content: " ";
  width: 58vw;
  height: 55vw;
  transform: rotate(260deg);
  position: absolute;
 
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  z-index: -5;
}

.small-agency-home-header-bg::after {
  background-image: url("../bg-acueil.png");
  background-size: cover;
  background-position: calc(100% - 120px) 394px;
  top: -46%;
  right: -20%;
  transform: rotate(0deg);
  box-shadow: -5px 0 30px rgb(0 0 0 / 10%);
}

.banner-content {
  padding: 200px 0 100px;
}

.banner-content .custom-btn {
  margin-top: 2rem;
}

.bg-shape {
  position: absolute;
  z-index: -1;
  opacity: 0.9;
  max-width: 100% !important;
}

.shape-home-banner {
  left: 3%;
  top: 19rem;
}

div[class="col-lg-6"] > h2, div[class="col-lg-6"] > p {
  font-weight: bold;
}

h1 {
  margin-bottom: 1.1rem;
}

/* END BANNER ------
--------------------- */


/* INNER PAGE -----
---------------------- */
.inner-page {
  position: relative;
}

.inner-page::before {
  content: " ";
  position: absolute;
  top: 10;
  right: 0;
  left: 0;
  height: 30vh;
  display: block;
  z-index: 1;
}

.page-wrapper {
  position: relative;
  z-index: 2;
  transition: all .5s ease;
}

.page-wrapper::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 45vh;
  display:inline-block;
}

.inner-page-banner {
  padding-top: 9.5rem;
  padding-bottom: 3px;
  margin-bottom: 6.5rem;
  position: relative;
}



.inner-page-banner h1 {
  color: #050505;
}
.inner-page-banner h2 {
  font-size: 3.8rem;
  color: rgb(255, 255, 255);
}

.inner-page-banner p {
  font-size: 2.2rem;
color : rgb(0, 0, 0) ; }

.inner-banner-with-btn {
  padding-bottom: 0;
}

.inner-banner-with-btn::after {
  display: none;
}

.inner-banner-with-btn .custom-btn {
  margin-top: 2rem;
}

.inner-page-shape-banner-right {
  left: auto;
  top: -6%;
  opacity: 0.1;
}

.inner-page-shape-banner-left {
  left: auto;
  top: 5%;
  opacity: 0.3;
}

/* END INNER PAGE ----
----------------------- */


/* SERVICE -------
-------------------- */
.content-block {
  height: 100%;
  display: block;
  background: #fff;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  outline: none;
}

.content-block img {
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.content-block.service-box {
  padding: 5.6rem 3rem 2.2rem;
}

.content-block h5 {
  margin-bottom: 1.9rem;
  text-align: left;
}

.content-block::before {
  content: " ";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 50px rgba(166, 209, 237, 0.2);
  z-index: -2;
}

.content-block::after {
  content: " ";
  width: 95%;
  height: 95%;
  z-index: -3;
  position: absolute;
  top: auto;
  bottom: 1px;
  left: 1px;
  border-radius: 12px;
  background-image: linear-gradient(100deg, #38B6FF, #0A4A78);
  background-repeat: repeat-x;
  outline: 1px solid transparent;
  transition: all 0.5s;
}

.content-block:hover {
  text-decoration: none;
}

.content-block:hover .icon-container i {
  color: #fff;
}

.content-block:hover .icon-container::after {
  opacity: 1;
}

.content-block:hover::after {
  transform: rotate(2deg) translateX(-5px) translateY(16px);
}

.common-list-items {
  list-style-type: none;
  padding-left: 35px;
}

.common-list-items li {
  position: relative;
  margin-bottom: 15px;
}

.common-list-items li i {
  top: 0;
  left: -35px;
  line-height: 3rem;
}

.image-with-description {
  position: relative;
}

.image-with-description img {
  border-radius: 10px;
}

.image-with-description .common-list-items {
  padding-top: 1rem;
}

.image-with-description-shape-bg {
  right: -8%;
  transform: rotate(90deg);
  top: 20%;
  z-index: 0;
}

.service-process-tab,
.location-process-tab {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.service-process-tab img,
.location-process-tab img {
  border-radius: 10px;
}

.service-process-tab .tab-pane:last-child .text-only-btn,
.location-process-tab .tab-pane:last-child .text-only-btn {
  display: none;
}

.nav-tabs {
  display: inline-flex;
  border: none;
  background: #fff;
  justify-content: center;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 2rem 0 1.2rem;
  margin-bottom: 7rem;
}

.nav-tabs .nav-link {
  border: none;
  padding: 1rem 4rem;
  color: #69798d;
  position: relative;
  z-index: 1;
}

.nav-tabs .nav-link:hover img,
.nav-tabs .nav-link.active img {
  filter: grayscale(0);
}

.nav-tabs .nav-link::before {
  content: " ";
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -30px;
  width: 6rem;
  height: 8rem;
  border-radius: 50px;
  background-image: linear-gradient(150deg, #f5fbff, rgba(255, 255, 255, 0));
  background-repeat: repeat-x;
  z-index: -1;
}

.nav-tabs .nav-link i {
  width: 6rem;
  height: 7rem;
  background: linear-gradient(150deg, rgba(255, 255, 255, 0), #f5faff);
  font-size: 3.5rem;
}

.nav-tabs .nav-link span {
  font-family: "Hind Vadodara", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  display: block;
}

.nav-tabs .nav-link img {
  filter: grayscale(1);
  margin-bottom: 10px;
}

.nav-tabs .nav-link p {
  margin-top: 10px;
}

.nav-tabs .nav-item:not(:last-child) .nav-link::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  font-family: "Hind Vadodara", sans-serif;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  line-height: 3rem;
  transform: translateY(-100%);
}

.service-tab-content h4,
.location-tab-content h4 {
  margin-bottom: 1.7rem;
}

/* END SERVICE -------
------------------------ */


/* CASE STUDY -----
------------------------- */
[class^=".grad-style-"] h2,
[class^=".grad-style-"] p {
  color: #fff;
}

.case-study {
  margin: 0rem 0 10rem;
  position: relative;
}

.case-study .container {
  z-index: 2;
  position: relative;
}

.case-study h2,
.case-study p {
  color: #fff;
}

.case-study .img-container img {
  position: relative;
  bottom: -40px;
}

.case-study .btn-container {
  padding-top: 2rem;
}

.case-study .video-play-btn {
  width: 65px;
  height: 65px;
  line-height: 65px;
  color: #fff;
  box-shadow: none;
  margin-left: 15px;
}

.case-study .video-play-btn:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.shape-case-study {
  top: 40%;
  left: -20%;
}

.shape-case-studies-details {
  right: -15%;
  bottom: -40%;
}

.small-agency-case-study {
  position: relative;
  margin-bottom: 8.5rem;

}

.small-agency-case-study::before,
.small-agency-case-study::after {
  content: " ";
  position: absolute;
  top: 0;
  bottom: -2rem;
  left: 0;
  right: 160px;
  display: block;
  border-top-right-radius: 350px;
  border-bottom-right-radius: 350px;
}

.small-agency-case-study::after {
  bottom: 0;
  background-image: url("../back0.jpg");
  background-size:cover;

}

.case-study-slider .slick-arrow {
  bottom: -52px;
}

.case-study-slider .slick-prev,
.case-study-slider .slick-prev:hover,
.case-study-slider .slick-prev:focus {
}

.case-study-slider .slick-next,
.case-study-slider .slick-next:hover,
.case-study-slider .slick-next:focus {
}

.case-study-content {
  padding-top: 8rem;
  margin-bottom: 1rem;
  position: relative;
}

.case-study-content img {
  border-radius: 10px;
}

.case-study-showcase {
  padding-top: 1.5rem;
}

.case-study-showcase [class*=col-] {
  padding: 0 45px;
  margin-bottom: 6rem;
}

.case-study-showcase .custom-btn {
  margin-bottom: 6rem;
}

.case-study-content-block .txt-content {
  padding: 2rem 3rem 1.2rem;
  background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.case-study-content-block .txt-content h5 {
  margin: 1.2rem 0;
}

.case-study-challenge-bg {
  position: absolute;
  top: 0;
  right: -240px;
  opacity: 0.6;
}

.case-study-solutions-bg {
  position: absolute;
  bottom: -220px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.6;
}

.scoreboard .scores {
  padding-left: 9rem;
  padding-right: 2rem;
  position: relative;
}

.scoreboard [class^=col-]:not(:last-child) .scores::after {
  content: " ";
  position: absolute;
  top: 20px;
  bottom: 20px;
  width: 2px;
  right: 0;
  display: block;
  background: #edf7ff;
}

.scoreboard .score {
  padding-left: 50px;
  font-size: 2.4rem;
  line-height: 4rem;
  color: #485768;
  margin-bottom: 2.2rem;
  position: relative;
}

.scoreboard .score i {
  font-size: 1.5rem;
  width: 3rem;
  line-height: 3rem;
  background: #f2f2ff;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  color: #6245fe;
  position: absolute;
  top: 7px;
  left: 0;
}

.modal .modal-content .scoreboard h3 {
  margin-bottom: 29px;
}

.case-studies-grid-wrapper {
  padding-bottom: 0.5rem;
}

.image-with-description-block {
  padding: 6.5rem 0 6.5rem;
}

.scroreboard-wrapper {
  max-width: 102rem;
  margin: 0 auto;
  padding: 2.5rem 0 0.6rem;
}

.scroreboard-wrapper [class*=col-]:nth-of-type(odd) .scoreboard-content::after {
  content: " ";
  position: absolute;
  top: 2rem;
  right: -15px;
  bottom: 2rem;
  background: #edf7ff;
  display: block;
  width: 2px;
}

.scoreboard-content {
  padding-left: 8rem;
  position: relative;
}

.scoreboard-content>div {
  font-size: 2.4rem;
  line-height: 4rem;
  color: #485768;
  font-weight: 500;
  margin-bottom: 2.2rem;
  padding-left: 5rem;
  position: relative;
}

.scoreboard-content>div i {
  display: block;
  position: absolute;
  top: 0.7rem;
  left: 0;
  font-size: 1.5rem;
  line-height: 3rem;
  text-align: center;
  width: 3rem;
  color: #af46fc;
  background: #f2f1ff;
  border-radius: 50%;
}

.inner-page-case-studies {
  padding-bottom: 0.5rem;
}

.slick-slide {
  outline: none;
}

/* END CASE STUDY ----
------------------------ */


/* PRICING ---
------------------ */
/* -- Pricing starts -- */
.pricing {
  background: url("https://cdn.kesency.com/images/pricing-bg-shape.svg") center calc(100% + 420px) no-repeat;
  background-size: 100% auto;
}

.pricing-wrapper {
  padding-top: 102px;
}

.pricing-wrapper [class^=col-] {
  margin-bottom: 3rem;
}

.pricing-block {
  padding: 9.5rem 4rem 4rem;
  position: relative;
}

.pricing-block::after {
  display: none;
}

.pricing-block .icon-container {
  position: absolute;
  left: 50%;
  top: -90px;
  transform: rotate(150deg) translateX(50%);
}

.pricing-block .price {
  font-family: "Hind Vadodara", sans-serif;
  font-size: 6rem;
  line-height: 1.6;
  font-weight: 900;
  padding-top: 2px;
  padding-bottom: 3px;
}

.pricing-block div,
.pricing-block h6 {
  width: 100%;
}

.pricing-block .custom-btn {
  width: 100%;
  margin-top: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.facilities>div {
  font-weight: 400;
  line-height: 3rem;
  padding: 1.7rem 0;
  border-bottom: 2px solid #edf7ff;
}

.facilities>div:last-child {
  margin-bottom: 4rem;
}

.inner-page-pricing {
  background: transparent;
}

.inner-page-pricing .pricing-wrapper {
  padding-top: 128px;
  padding-bottom: 3.5rem;
}

.color-p-desc {
  color: #69798d;
}

/* END PRICING ---
-------------------- */


/* CLIENT ---
---------------- */
.clients-wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
}

.clients .client {
  max-width: 10%;
  flex: 0 0 20%;
  text-align: center;
  margin: 25px 0;
  align-self: center;
}

/* -- Latest News css -- */
.light-grey-grad {
  background-image: linear-gradient(to bottom, #fcfdff, #f9fcff);
  background-repeat: repeat-x;
}

.news-slider {
  padding-bottom: 6rem;
}

.news-slider .item {
  margin-bottom: 3rem;
  outline: none;
  border: none;
}

.news-slider .item h5 {
  padding: 2rem 3rem 2.1rem;
  background: #fff;
  margin-bottom: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.news-slider .item h5 span {
  margin-top: 0;
}

.news-slider .item:focus {
  outline: none;
  border: none;
}

.news-modal .modal-content {
  padding-bottom: 5.6rem;
}

.news-modal .modal-dialog {
  max-width: 1020px;
}

.blog-details-related-post {
  padding-bottom: 0.5rem;
}

.blog-by-category {
  padding-bottom: 0.5rem;
}

.blog-by-category.single-cat {
  padding-bottom: 0;
}

.blog-by-category .custom-btn {
  margin-bottom: 6rem;
}

.blog-grid .item {
  margin-bottom: 6rem;
}

.blog-grid .content-block h5 {
  padding: 2rem 3rem 2.1rem;
  margin-bottom: 0;
  background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.article-wrapper {
  padding-bottom: 2.5rem;
}

.blog-post-details {
  position: relative;
}

.shape-blog-details {
  top: 40%;
  right: -20%;
}

.blog-details {
  padding: 5.9rem 0 3.8rem;
}

.blog-details h2 {
  margin-bottom: 1.1rem;
}

.blog-details h2 span {
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: #69798d;
  display: block;
  font-weight: 700;
  margin-bottom: 1rem;
}

.blog-details .social-icons {
  margin-bottom: 1.7rem;
}

.blog-details .social-icons a {
  background: #f1efff;
  color: #0057ff;
}

.blog-details img {
  margin: 3.9rem 0 5.8rem;
  border-radius: 10px;
}

.post-info {
  margin-bottom: 3.1rem;
}

.post-info a {
  font-size: 1.8rem;
  line-height: 3rem;
  color: #69798d;
  text-decoration: none;
  margin-right: 2rem;
  transition: all 0.5s;
}

.post-info a i {
  margin-right: 1rem;
  vertical-align: -2px;
}

.post-info a:hover {
  color: #ff7cb0;
}

.article-inner-title {
  margin: 4.6rem 0 2.7rem;
}

.author-details {
  padding: 5rem 0 3.8rem;
}

.author-details h2 {
  margin-bottom: 3.3rem;
}

.author-thumbnail {
  margin-right: 3rem;
}

.blog-details-prev-next {
  margin-bottom: 3.8rem;
}

.blog-details-prev-next a {
  font-family: "Hind Vadodara", sans-serif;
  font-size: 1.8rem;
  line-height: 1;
  font-weight: 700;
  color: #69798d;
  text-decoration: none;
  transition: all 0.5s;
}

.blog-details-prev-next a i {
  vertical-align: -1px;
  margin-right: 0.5rem;
}

.blog-details-prev-next a:hover {
  color: #6245fe;
}

.blog-load {
  color: red;
  transition: all .2s ease-in-out;
  display: none;
}

/* END CLIENT ---
------------------- */


/* NEWSLETTER -----
------------------------ */
.newsletter {
  padding: 5.5rem 0 10rem;
  z-index: 100;
}

.newsletter-form {
  max-width: 95rem;
  margin: 0 auto;
  padding-top: 3.2rem;
}

.newsletter-form input,
.newsletter-form .custom-btn {
  margin-bottom: 3rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

/* END NEWSLETTER ----
------------------------- */


/* CONTACT ----
---------------------- */
.contact {
  padding-top: 19.6rem;
  overflow: hidden;
}

.contact .grey-bg {
  position: relative;
  z-index: 1;
}

.contact .grey-bg .shape-contact {
  position: absolute;
  top: -60%;
  left: -10%;
  z-index: -1;
  opacity: 0.1;
}

.contact .grey-bg .shape-contact-2 {
  position: absolute;
  top: 20%;
  right: -10%;
  z-index: -1;
  opacity: 0.1;
}

.contact-wrapper {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  padding: 5rem 6rem 6rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.contact-wrapper.common-contact-wrapper {
  margin-top: -13rem;
  margin-bottom: 8rem;
}

.contact-wrapper.common-contact-wrapper::after {
  content: " ";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 120%;
  height: 120%;
  background: #f8fcff;
  display: block;
  transform: translate(50%, 50%);
  border-radius: 50%;
  z-index: -1;
}

.contact-wrapper.contact-page-form-wrapper {
  transform: translateX(75px);
  margin-bottom: 3rem;
  margin-top: 1.5rem;
}

.contact-wrapper h3 {
  margin-bottom: 3.2rem;
  color: #0A4A78;
}

.form-wrapper {
  position: relative;
  right: 0;
  transition: all 0.3s ease-in-out;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 3rem;
  background: #fff;
}

.contact-form textarea {
  min-height: 18rem;
}

.contact-form .btn-wrapper {
  width: 100%;
  padding: 0 1.5rem;
  margin-top: 1rem;
}

.read-only {
  opacity: .7;
}



.success-message {
  color: #0057ff;
  font-weight: bold;
}

.success-input, .success-input:hover, .success-input:focus {
  border: 2px solid #0057ff;
}

.success-input::placeholder {
  color: #0057ff !important
}

.error-message {
  color: #FF1C1C !important;
  font-weight: bold !important;
}

.error-input, .error-input:hover, .error-input:focus {
  border: 2px solid #FF1C1C;
}

.error-input::placeholder {
  color: #FF1C1C !important;
}

.view-map-btn {
  display: inline-block;
  position: absolute;
  right: 26px;
  bottom: 60px;
  font-family: "Hind Vadodara", sans-serif;
  font-size: 1.6rem;
  line-height: 6.2rem;
  color: #69798d;
  font-weight: 500;
  margin-left: auto;
  border-radius: 50px;
  background: transparent;
  padding: 0 3rem;
  text-decoration: none;
  z-index: 99;
  transition: all 0.3s;
}

.view-map-btn i {
  font-size: 3rem;
  margin-left: 6px;
  position: relative;
  top: 8px;
  transition: all 0.3s;
}

.view-map-btn:hover {
  text-decoration: none;
  color: #69798d;
}

.contact-info h3 {
  margin-bottom: 2.4rem;
}

.contact-info.floating-contact-info {
  background: #fff;
  border: 2px solid #f1f0ff;
  border-radius: 10px;
  padding: 4rem 3rem 4rem 16.5rem;
  margin-bottom: 3.7rem;
  transform: translateX(-75px);
}

.contact-info.floating-contact-info .social-icons a {
  background: #f1efff;
  color: #0057ff;
}

.contact-info.floating-contact-info .social-icons a:hover {
  color: #0A4A78;
}

.social-icons-wrapper p {
  margin-bottom: 13px;
}

.social-icons {
  list-style: none;
  text-align: left;
  padding-left: 0;
  margin-bottom: 0;
}

.social-icons li {
  margin-bottom: 1.4rem;
  display: inline-block;
  margin-right: 1rem;
}

.social-icons li:hover a {
  color: #fff;
}

.social-icons li a:hover {
  background-color: #0057ff;
}

.social-icons a {
  text-decoration: none;
  font-size: 1.6rem;
  line-height: 3rem;
  color: #0A4A78;
  width: 3rem;
  height: 3rem;
  display: block;
  background: #a6d1ed;
  text-align: center;
  border-radius: 5px;
  transition: all 0.5s;
}

.address a,
.address span {
  display: block;
  font-family: "Hind Vadodara", sans-serif;
  font-size: 2.4rem;
  line-height: 3.4rem;
  font-weight: 900;
  text-decoration: none;
  transition: all 0.5s;
}

.address a:hover {
  color: #0A4A78;
}

.map-wrapper {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  z-index: 9;
  transition: all 0.3s ease-in-out;
}

.map-wrapper iframe {
  width: 100%;
  height: 100%;
}

.show-map .form-wrapper {
  right: 100%;
}

.show-map .map-wrapper {
  left: 0;
}

.show-map .view-map-btn {
  background: #fff;
  border-color: transparent !important;
}

.show-map .view-map-btn i {
  margin-right: 6px;
  margin-left: 0;
}

.err-msg,
.success-msg {
  font-size: 1.3rem;
  line-height: 1.5;
  color: #dd4b39;
  position: absolute;
  bottom: 6px;
  left: 15px;
}

.success-msg {
  color: #519718;
  bottom: 4px;
}

.whats-next-wrapper {
  padding: 1.3rem 0 3.9rem 2rem;
}

.whats-next-wrapper p {
  margin-bottom: 1rem;
  position: relative;
}

.whats-next-wrapper p i {
  position: absolute;
  top: 0;
  left: -2rem;
  font-size: 1.1rem;
  line-height: 3rem;
}

.location-tab-nav {
  background: transparent;
  box-shadow: none;
  padding-top: 0;
}

.location-tab-nav li {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 50px rgba(166, 209, 237, 0.2);
  margin: 0 15px;
  padding: 4rem 0 0;
}

.location-tab-nav li a::after {
  display: none;
}

.location-tab-content .tab-pane {
  padding: 0 75px;
}

.location-tab-content .tab-pane .map-wrapper {
  position: static;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
}

.location-tab-content .tab-pane .map-wrapper iframe {
  border: none;
}

.shape-work-places {
  top: 0;
  right: -20%;
}

/* END CONTACT ---
------------------------ */


/* ABOUT US ----
-------------------- */
.about-us {
  position: relative;
  padding: 8.1rem 0;
  margin: 10rem 0 6.5rem;
}

.about-us .bg-shape {
  right: -20%;
  bottom: -90%;
  transform: rotate(90deg);
}

.counter-wrapper {
  font-family: "Hind Vadodara", sans-serif;
  font-size: 1.8rem;
  line-height: 2.6rem;
  font-weight: 700;
  padding-top: 0.9rem;
}

.counter-wrapper br {
  display: none;
}

.inner-page-counter-wrapper {
  margin-bottom: 15px;
}

.inner-page-about-us {
  margin: 0;
  padding: 6.5rem 0;
}

.img-round {
  border-radius: 10px;
}

.counter-block {
  font-size: 4.8rem;
  line-height: 6.2rem;
  font-weight: 900;
}

.video-play-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 50%;
  width: 50%;
  border-top-right-radius: 70px;
  border-bottom-right-radius: 0px;
  z-index: 1;
  background-size:cover;
}

.video-play-bg .video-play-btn {
  position: absolute;
  top: 40px;
  right: 50px;
  z-index: 5;
}



.border-rad {
  align-items: flex-start;
}

.privacy-content {
  padding: 5rem 0 10.2rem;
}

/* END ABOUT US ----
----------------------- */


/* FEATURED PROJECTS ----
---------------------------- */
.filter-button-group {
  display: inline-block;
  margin: 1.2rem auto 3rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 50px rgba(166, 209, 237, 0.2);
  padding: 1.9rem 0;
}

.filter-button {
  font-family: "Hind Vadodara", sans-serif;
  font-size: 1.8rem;
  color: #69798d;
  font-weight: 700;
  background: transparent;
  border: none;
  margin: 0 2rem;
  cursor: pointer;
}

.filter-button:focus {
  outline: none;
}

.project-showcase .grid {
  padding-top: 4rem;
  transition: all 1s;
}

.featured-project-showcase,
.blog-grid {
  padding-top: 4rem;
}

.featured-project-showcase .custom-btn {
  margin-bottom: 6rem;
}

.project-showcase .custom-btn {
  margin-bottom: 5.5rem;
}

.grid-item {
  margin-bottom: 6rem;
}

.featured-content-block h5 {
  padding: 2rem 3rem 2.1rem;
  background: #fff;
  margin-bottom: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.featured-project-carousel .carousel-inner {
  border-radius: 10px;
  overflow: hidden;
}

.featured-project-carousel .carousel-control-prev-icon {
  background: url("") center center no-repeat;
}

.featured-project-carousel .carousel-control-next-icon {
  background: url("") center center no-repeat;
}

.featured-project-modal .custom-btn {
  margin-top: 1.2rem;
}

.featured-project-modal .modal-content {
  padding: 75px;
}

.featured-project-modal .text-content {
  padding-left: 2rem;
}

.featured-project-modal-bg,
.news-modal-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(40%, 40%);
}

.featured-projects {
  position: relative;
  padding-bottom: 0.5rem;
}

.featured-projects .bg-shape {
  bottom: -10%;
  left: -10%;
}

.featured-item {
  position: relative;
}

.featured-item::before {
  content: "featured";
  position: absolute;
  left: 15px;
  top: 3rem;
  width: 120px;
  height: 30px;
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  z-index: 3;
}

.featured-item .img-container::after,
.featured-item .img-container::before {
  content: " ";
  position: absolute;
  top: 30px;
  left: 120px;
  display: block;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #0A4A78;
}

.featured-item .img-container::after {
  transform: rotateX(180deg);
  top: 45px;
}

.portfolio {
  padding-bottom: 1.8rem;
}

.shape-project {
  left: -20%;
}

/* END FEATURED PROJECTS ---
--------------------------------- */


/* FOOTER ------
--------------------- */
.page-footer {
  padding: 4rem 0;
}

.footer-content p {
  margin-bottom: 0;
}

.footer-content p a {
  color: #69798d;
  text-decoration: none;
  transition: all 0.5s;
}

.footer-content p a:hover {
  color: #0A4A78;
}

.small-agency-footer {
  position: relative;
  z-index: 6;
  overflow: hidden;
}

.small-agency-footer .shape-footer-top {
  position: absolute;
  top: -90%;
  z-index: 0;
  left: -8%;
  opacity: 0.1;
  transform: rotate(90deg);
}

.small-agency-footer .shape-footer-bottom {
  position: absolute;
  bottom: -80%;
  z-index: 0;
  right: -9%;
  opacity: 0.1;
  transform: rotate(90deg);
}

#logo-footer {
  background-color: #a6d1ed;
  padding: 5px 7px;
  border-radius: 5px;
}

.small-agency-footer .footer-nav-wrapper {
  position: relative;
  padding: 8.9rem 0 7.8rem;
}

.small-agency-footer .footer-nav-wrapper .social-icons {
  margin-bottom: 3rem;
  text-align: end;
}

.small-agency-footer .footer-nav-wrapper h5 {
  font-weight: 400;
  margin-bottom: 2.1rem;
}

.small-agency-footer .footer-nav-wrapper .footer-nav {
  padding-left: 5rem;
}

.small-agency-footer .footer-nav-wrapper .footer-menu {
  padding-left: 0;
}

.small-agency-footer .footer-nav-wrapper .footer-menu li {
  list-style: none;
}

.small-agency-footer .footer-nav-wrapper .footer-menu li a {
  line-height: 3.6rem;
  color: #fff;
  text-decoration: none;
  transition: all 0.5s;
}

.small-agency-footer .footer-nav-wrapper .footer-menu li a:hover {
  color: #0e1f33;
}

.small-agency-footer .footer-nav-wrapper .footer-menu li.active a {
  color: #0A4A78;
}

.small-agency-footer .footer-bottom {
  background-color: #0e1f33;
  padding: 30px 0;
}

.small-agency-footer .footer-bottom p {
  margin-bottom: 0;
  color: #69798d;
  position: relative;
  z-index: 8;
}

.small-agency-footer .footer-bottom p a {
  text-decoration: none;
  transition: all 0.5s;
  color: #69798d;
}

.small-agency-footer .footer-bottom p a:hover {
  color: #0A4A78;
}

.footer-logo img {
  max-width: 15rem;
  display: block;
}

/* END FOOTER ------
--------------------- */


/* RESPONSIVE -------
---------------------- */
@media screen and (min-width: 1600px) {
  .banner::after {
 
    background-size: 93%;
    top: -46%;
    right: -20%;
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-box-shadow: -5px 0 30px rgba(0, 0, 0, 0.1);
    box-shadow: -5px 0 30px rgba(0, 0, 0, 0.1);
  }

  .small-agency-home-header-bg::after {

    background-size: 125%;

    top: -46%;
    right: -20%;
  }
}

@media screen and (max-width: 1600px) {
  .banner::before {
    
    width: 54vw;
    height: 54vw;
      margin-top: 80px!important;
    
  }

  .banner::after {
    top: -100px !important;
    right: -100px !important;
    width: 54vw;
    height: 54vw;
    margin-top: 80px!important;
  }
}

@media (min-width: 1199px) and (max-width: 1600px) {
  .case-study-slider .slick-next {
    right: 240px;
  }

  .case-study-slider .slick-prev {
    right: 300px;
  }

  .case-study-slider .slick-arrow {
    bottom: -72px;
  }
  .banner::before {
    
    width: 54vw;
    height: 54vw;
      margin-top: 80px!important;
    
  }

  .banner::after {
    top: -100px !important;
    right: -100px !important;
    width: 54vw;
    height: 54vw;
    margin-top: 80px!important;
  }
}

@media screen and (max-height: 750px) {


  .inner-page-banner h1, .inner-page-banner p {
    color: #020202;
    font-weight: bold;
  }
}

@media screen and (max-width: 2017px) {
  .case-study {
    padding: 5rem 0;
  }

  .common-slider {
    left: auto;
  }

  .content-block img {
    width: 100%;
  }

  .case-study .img-container img {
    bottom: auto;
  }

  .case-study-slider .slick-slide {
    padding: 0 15px;
  }

  .common-slider .slick-prev,
  .case-study-slider .slick-prev {
    right: 110px;
  }

  .common-slider .slick-next,
  .case-study-slider .slick-next {
    right: 50px;
  }

  .contact .social-icons {
    padding-left: 0;
  }

  .navbar {
    padding: 2rem 1.5rem;
  }

  .navbar-nav .custom-dropdown-menu {
    top: 135%;
  }

  .navbar-nav .nav-item {
    margin-left: 2rem;
  }

  .z-3 {
    z-index: 3;
  }

  .banner::before {
    top: -120px;
    right: -190px;
    z-index: 1;
  }

  .banner::after {
    top: -150px;
    right: -250px;
    z-index: 1;
    background-position: calc(100% - 40px) 90px;
  }

  .banner-content {
    position: relative;
    z-index: 2;
  }

  .banner {
    height: auto;
    overflow: hidden;
  }

  .pricing-block .price {
    font-size: 4rem;
  }

  .small-agency-case-study::before,
  .small-agency-case-study::after {
    right: 0;
    border-radius: 0;
  }

  .small-agency-footer .footer-nav-wrapper .footer-nav {
    padding-left: 0;
  }

  .counter-wrapper {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 1141px) {
  .navbar-nav .nav-link.custom-btn {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .navbar {
    background: #fff;
    z-index: 999;
    padding: 15px;
    box-shadow: 0 0 10px rgba(166, 209, 237, 0.3);
  }

  .navbar img {
    width: 80%;
  }

  .navbar .navbar-nav .custom-dropdown-menu {
    top: 125%;
  }

  .navbar-nav .nav-link.custom-btn {
    display: inline-block;
  }

  .counter-wrapper {
    font-size: 1.6rem;
  }

  .counter-block {
    font-size: 3rem;
  }

  .content-block::after {
    width: 90%;
    height: 90%;
  }

  .featured-project-modal .text-content {
    padding-left: 0;
    margin-top: 30px;
  }

  .case-study .img-container img {
    bottom: -65px;
    width: 100%;
    padding: 0 10%;
  }

  .pricing-wrapper {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .pricing-block {
    height: auto;
    margin-bottom: 102px;
  }

  .page-wrapper {
    margin-top: 115px;
  }

  .banner.small-agency-home-header-bg {
    margin-top: 5rem;
  }

  .clients .client {
    max-width: 33.333333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    padding: 0 15px;
  }

  .clients .client img {
    max-width: 100%;
  }

  .clients-wrapper {
    padding-top: 4px;
  }

  .contact-wrapper {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .address a,
  .address span {
    font-size: 1.8rem;
  }

  .pricing {
    padding-bottom: 0;
  }

  .navbar-nav {
    padding: 30px 15px;
    background-color: #f6f6f6;
    margin-top: 2rem;
    text-align: center;
  }

  .navbar-nav .nav-item {
    width: 100%;
    margin-left: 0;
  }

  .navbar-nav .custom-btn {
    margin-top: 8px;
    margin-bottom: 5px;
  }

  .navbar-nav .nav-item .nav-link:not(.custom-btn) {
    margin-bottom: 2rem;
  }

  .case-study-content img {
    margin-top: 3rem;
    width: 100%;
  }

  .scoreboard .scores {
    padding-left: 4rem;
  }

  .small-agency-footer .footer-nav-wrapper {
    padding: 6rem 0 2.3rem;
  }

  .small-agency-footer .footer-nav-wrapper .footer-nav {
    margin-bottom: 2.5rem;
  }

  .small-agency-footer .footer-nav-wrapper .social-icons {
    margin-top: 4.5rem;
    margin-bottom: 2.3rem;
  }

  .navbar-nav .nav-item .nav-link:not(.custom-btn) {
    margin-bottom: 0;
    text-align: center;
  }

  .navbar-nav .custom-dropdown-menu {
    position: initial;
    border: none;
    text-align: center;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .common-list-items {
    margin-bottom: 4.1rem;
  }

  .service-process-tab img {
    margin-bottom: 3.5rem;
  }

  .inner-page-about-us img {
    margin-bottom: 3.5rem;
  }

  .get-a-quote-modal button.close {
    top: 3.5rem;
    right: 3rem;
  }

  .contact-wrapper.contact-page-form-wrapper,
  .contact-info.floating-contact-info {
    -ms-transform: translate(0);
    transform: translate(0);
  }

  .get-a-quote-modal .contact-info.floating-contact-info {
    -ms-transform: translate(0);
    transform: translate(0);
    margin-bottom: 0;
    padding: 3rem;
    margin-top: 3rem;
  }

  .navbar .dropdown>a::after {
    vertical-align: middle;
  }

  .nav-tabs .nav-link {
    padding: 1rem 2rem;
  }

  .about-us .bg-shape {
    display: none;
  }
}

@media screen and (min-width: 767px) {
  .popuptext {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 3rem;
    margin-bottom: 1.3rem;
  }

  h2 {
    font-size: 2.4rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.8rem;
  }

  h5 {
    font-size: 1.8rem;
  }

  h6 {
    font-size: 1.6rem;
  }

  body,
  p {
    font-size: 1.6rem;
  }

  br {
    display: none;
  }

  .section-padding {
    padding: 2.4rem 0 3rem;
  }

  .page-wrapper {
    margin-top: 30px;
  }

  .section-subheading {
    font-size: 1.8rem;
    line-height: 1.6;
  }

  .banner-content {
    padding: 31rem 0 6rem;
  }

  .banner-content .custom-btn {
    margin-top: 1.3rem;
  }

  .banner::before,
  .banner::after {
    width: 100vw;
    height: 100vw;
  }

  .video-play-bg {
    position: relative;
    left: auto;
    right: auto;
    width: 90vw;
    height: 68vw;
    margin-bottom: 5.3rem;
  }

  .video-play-bg::after {
    right: 0;
    top: -20px;
  }

  .video-play-bg .video-play-btn {
    top: 50%;
    -ms-transform: translateY(-63%);
    transform: translateY(-63%);
  }

  .about-us {
    padding: 3rem 0 2rem;
    margin: 0;
  }

  .counter-wrapper {
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.6;
    font-weight: 500;
  }

  .case-study {
    margin: 3rem 0 5rem;
    padding: 5.4rem 0;
  }
  .wrapper {
    height: 58rem;
    background-image: url('../background-cover.svg'), url('../mask.png'),
      url('../background.svg');
    background-position: right -500px bottom 0, left, center;
    background-size: cover, cover, cover;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-blend-mode: normal, color-dodge, normal;
  }
  
  @media screen and (max-width: 768px) {
    .heroSection {
      flex-direction: column;
      gap: 20px;
    }
  
    .heroSection div {
      width: 100%;
      text-align: center;
    }
  
    .heroText h1 {
      font-weight: normal;
      font-size: 50px;
      line-height: 1.2;
    }
  }
  
  .project-showcase .custom-btn {
    margin-bottom: 1.6rem;
    margin-top: 1rem;
  }

  .grid-item {
    margin-bottom: 3rem;
  }

  .filter-button-group {
    margin-bottom: 4rem;
  }

  .filter-button {
    font-size: 1.4rem;
    padding: 0 15px;
    margin: 5px;
    border-radius: 50px;
    background: #f5f5f5;
  }

  .filter-button.is-checked {
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .btn-big {
    font-size: 1.4rem;
    line-height: 20px;
    padding: 1.6rem 2rem;
  }

  .case-study .video-play-btn {
    width: 5.2rem;
    height: 5.2rem;
    line-height: 5.2rem;
  }

  .case-study-slider .slick-arrow {
    bottom: -7.2rem;
  }

  .common-slider .item {
    padding: 0 15px;
  }

  .common-slider {
    padding-top: 1.1rem;
  }

  .team-slider .slick-arrow,
  .news-slider .slick-arrow {
    bottom: -53px;
  }

  .team {
    padding-bottom: 1.8rem;
  }

  .pricing-wrapper {
    padding-top: 7.7rem;
  }

  .pricing-block .price {
    font-size: 3rem;
  }

  .clients {
    padding: 2rem 0;
  }

  .clients .client {
    margin: 10px 0;
  }

  .pricing-wrapper [class^="col-"]:last-child .pricing-block {
    margin-bottom: 0;
  }

  .clients-wrapper {
    padding-top: 1px;
    padding-bottom: 0;
  }

  .news-slider .slick-slide {
    height: auto;
  }

  .contact-wrapper {
    margin-top: -6rem;
  }

  input,
  textarea {
    padding: .5rem 1.5rem;
  }

  .view-map-btn {
    position: relative;
    margin-top: 2rem;
    right: auto;
    bottom: auto;
    border: 1px solid #69798d !important;
    width: 100%;
    text-align: center;
    height: 5rem;
    line-height: 5rem;
  }

  .contact-wrapper {
    padding-bottom: 3.4rem;
    margin-bottom: 5rem;
  }

  .contact-form textarea {
    min-height: 15rem;
  }

  .contact-info {
    margin-bottom: 3rem;
  }

  .footer-content {
    display: block !important;
  }

  .page-footer {
    padding: 2rem 0;
  }

  .modal button.close {
    top: 1rem;
    right: 1rem;
  }

  .modal {
    padding: 3rem !important;
  }

  .modal .modal-content {
    padding: 3rem 3rem 0;
  }

  .featured-project-modal .modal-content {
    padding: 3rem;
  }

  .news-modal .modal-content {
    padding-bottom: 1.1rem;
  }

  .scoreboard .score {
    font-size: 1.8rem;
    line-height: 1.4;
    padding-left: 4.5rem;
  }

  .scoreboard .score i {
    margin-right: 0;
    top: 0;
  }

  .case-study-content {
    padding-top: 4.4rem;
  }

  .social-icons {
    text-align: left;
    margin-bottom: 4rem;
  }

  .social-icons li {
    display: inline-block;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 5rem;
  }

  .team .bg-shape.shape-2 {
    display: none;
  }

  .latest-news {
    padding-bottom: 36px;
  }

  .scoreboard .scores {
    padding: 0;
  }

  .scoreboard [class^="col-"]:not(:last-child) .scores::after {
    display: none;
  }

  .contact .grey-bg .shape-contact-2 {
    right: -10%;
  }

  .contact {
    padding-top: 7rem;
  }

  .equalHeight {
    height: auto !important;
  }

  .counter-wrapper br {
    display: block;
  }

  .contact-form .btn-wrapper .custom-btn {
    width: 100%;
  }

  .about-us {
    padding: 3rem 0 2.4rem;
  }

  .featured-project-showcase .custom-btn {
    margin-bottom: 0;
  }

  .newsletter {
    padding: 2.4rem 0 3rem;
    z-index: 100;
  }

  .service-process-tab,
  .location-process-tab {
    padding-bottom: 0;
  }

  .text-only-btn {
    line-height: 1;
    margin-bottom: 0;
  }

  .featured-project-showcase {
    padding-top: 1rem;
  }

  .navbar-collapse {
    max-height: 80vh;
  }

  .navbar-nav {
    padding: 15px 15px 30px;
  }

  .navbar-brand {
    max-width: 15rem;
  }

  .navbar-nav .nav-item .nav-link:not(.custom-btn) {
    padding: 1rem 0;
  }

  .latest-news {
    margin-bottom: 0;
    padding-bottom: 1.7rem;
  }

  .case-study .img-container img {
    bottom: -44px;
  }

  .inner-page-banner {
    padding-top: 10px;
    margin-bottom: 3rem;
  }

  .inner-page-banner p {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  .pricing-block {
    margin-bottom: 7.3rem;
  }

  .pricing-wrapper [class^="col-"]:last-child {
    margin-bottom: 0;
  }

  .filter-button-group {
    margin-bottom: 0;
  }

  .project-showcase .custom-btn {
    margin-bottom: 0;
  }

  .case-study-showcase {
    padding-top: .6rem;
  }

  .case-study-showcase [class*="col-"] {
    margin-bottom: 3rem;
    padding: 0 15px;
  }

  .case-study-showcase [class*="col-"]:last-child {
    margin-bottom: 0;
  }

  .counter-wrapper {
    margin-bottom: 0;
  }

  .team-grid {
    padding-top: .6rem;
  }

  .team-grid .item {
    margin-bottom: 2.1rem;
  }

  .team-grid .item:last-child {
    margin-bottom: 0;
  }

  .team-grid-wrapper {
    padding-bottom: 1.1rem;
  }

  .blog-grid {
    padding-top: 1rem;
  }

  .blog-grid .item {
    margin-bottom: 4rem;
  }

  .blog-grid .item:last-child {
    margin-bottom: 0;
  }

  .blog-grid .custom-btn {
    margin-top: 4rem;
    margin-bottom: 0;
  }

  .image-with-description-block {
    padding: 2.4rem 0 3rem;
  }

  .image-with-description-block:nth-of-type(1) {
    padding-top: 2.4rem;
  }

  .scroreboard-wrapper {
    padding: 2.7rem 0 1rem;
  }

  .scoreboard-content>div {
    font-size: 1.8rem;
    line-height: 3rem;
    margin-bottom: 1rem;
  }

  .scoreboard-content>div i {
    top: 3px;
  }

  .scroreboard {
    padding-bottom: 1.3rem;
  }

  .scoreboard-content {
    padding-left: 0;
  }

  .case-study-showcase .custom-btn {
    margin-bottom: 0;
    margin-top: 4rem;
  }

  .our-process {
    padding-bottom: 2.1rem;
  }

  .nav-tabs {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom: 3rem;
    padding: 0 0 .2rem;
  }

  .nav-tabs .nav-item {
    width: 9rem;
    padding: 1.5rem 0 .5rem;
    margin: 0 .5rem 1rem;
    background-color: #eee;
    border-radius: 10px;
  }

  .nav-tabs:not(.location-tab-nav) .nav-link {
    padding: 0;
  }

  .nav-tabs:not(.location-tab-nav) .nav-link::before {
    width: 3rem;
    height: 6rem;
    margin-left: -15px;
    top: -3px;
  }

  .nav-tabs:not(.location-tab-nav) .nav-link i {
    font-size: 1.8rem;
  }

  .nav-tabs:not(.location-tab-nav) .nav-link span {
    font-size: 1rem;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    background-color: #eee;
  }

  .nav-tabs .nav-item:not(:last-child) .nav-link::after {
    font-size: 12px;
    right: -1.1rem;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: none;
  }

  .tab-pane p:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  .tab-pane .text-only-btn {
    margin-top: 1.9rem;
  }

  .blog-details {
    padding: 2.4rem 0 3rem;
  }

  .blog-details img {
    margin: .9rem 0 2.7rem;
  }

  .blog-details p:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  .article-inner-title {
    margin-bottom: 2.3rem;
  }

  .author-details {
    padding: 1.4rem 0 3rem;
  }

  .author-details h2 {
    margin-bottom: 3.1rem;
  }

  .blog-details-prev-next {
    margin-bottom: .2rem;
  }

  .post-info a {
    font-size: 1.4rem;
    margin-right: 1rem;
  }

  .contact-wrapper.contact-page-form-wrapper,
  .contact-info.floating-contact-info {
    -ms-transform: translate(0);
    transform: translate(0);
  }

  .contact-wrapper.contact-page-form-wrapper {
    margin-top: .6rem;
  }

  .contact-info.floating-contact-info {
    padding: 3.5rem 3rem 2.6rem;
    margin-bottom: 0;
  }

  .contact-info .social-icons {
    margin-bottom: 0;
  }

  .whats-next-wrapper {
    padding-bottom: 1.9rem;
  }

  .location-tab-content .tab-pane {
    padding: 0;
  }

  .location-tab-nav .nav-item {
    width: auto;
    padding: 5rem 1.5rem .2rem;
    margin: 0 0 15px;
  }

  .modal.show .modal-dialog {
    margin-bottom: 3rem;
  }

  .modal-open .get-a-quote-modal {
    padding: 20px 15px 30px !important;
  }

  .get-a-quote-modal .modal-content {
    padding: 0;
  }

  .get-a-quote-modal button.close {
    top: 2rem;
    right: 3rem;
  }

  .news-slider {
    padding-bottom: 0;
  }

  .inner-page-pricing .pricing-wrapper {
    padding-top: 9.3rem;
  }
}

@media screen and (max-width: 421px) {
  .legal-info {
    float: none;
  }
}

/* END RESPONSIVE ----
------------------------ */
.wrapperco {
	padding-top: 5rem;
	padding-bottom: 5rem;
	
  

}

.containerco {

	display: flex;
	gap: 5rem;
	margin: auto;
  padding: 100px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.titleco {
	width: 50%;
  padding: 50px;
}
.titleco h1 {
	color: white;
}
.titleco p {
	color: #000000;
}

.contain:first-child {
	background-color: rgb(247, 246, 246);
}

.imgContainerco {
	position: relative;
	width: 20%;
	height: 100px;
}

.containerco form {
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: center;
	width: 50%;

	padding: 40px;
	padding-top: 4rem;
	padding-bottom: 4rem;
	border-radius: 15px;
	background: linear-gradient(to right, #0a4a78, #38b6ff);
	filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.131));
}

.containerco h6 {
	margin-bottom: 5px;
	color: rgb(252, 252, 252);
	font-weight: 400;
	font-size: 1rem;
}

.containerco input,
.containerco textarea {
	width: 100%;
	padding: 10px;
	border: none;
	border-radius: 15px;
	background-color: #fff;
	color:rgb(250, 248, 248);
	outline: none;
	font-size: 2.6rem;
}

.containerco textarea {
	resize: none;
}

.containerco button {
	width: 100%;
	padding: 10px 20px;
	border-radius: 25px;
	background-image: linear-gradient(45deg, #04266e, #0769be);
	color: white;
}

@media screen and (max-width: 768px) {
	.containerco {
		flex-direction: column;
	}

	.titleco {
		width: 100%;
		text-align: center;
	}

	.containerco form {
		width: 100%;
	}
}

/* Styles de base pour la page Espace carriere */
.inner-page-carriere {
  background-color: #f0f1fe;
  padding-bottom: 50px;
}

        .containercarriere {
          display: flex;
          justify-content: space-between;
          align-items: center;
          max-width: 1200px;
          margin-left: auto;
          margin-right: auto;
          margin-top: -80px;
          margin-bottom: 50px;
      
      }
      
      .columncarriere  {
          flex: 1;
          margin: 10px;
          overflow: hidden;
          position: relative;
      }
      
      /* Styles de l'image et de l'overlay */
      .imagecarriere  {
          position: relative;
          cursor: pointer;
          overflow: hidden;
          height: auto;
      }
      
      img {
          max-width: 100%;
          height: auto;
      }
      
      .overlaycarriere  {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.7);
          opacity: 0;
          transform: rotate(45deg);
          transition: transform 0.3s ease, opacity 0.3s ease;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: #fff;
      }
      
      /* Styles pour l'animation au survol */
      .columncarriere:hover .overlaycarriere  {
          opacity: 1;
          transform: rotate(0deg);
      }

/* Styles de base pour la page Partenaires */
.containerpartener {
  display: flex;
  justify-content: space-between; /* Pour espacer les colonnes */
  max-width: 1200px;
 margin: auto;
 margin-bottom: 50px;
}
.containerpartener h1 {
color: #000000;
}

.columnpartener {
  flex: 1;
  padding: 50px;
  margin: 10px;
  text-align: center;
  transition: transform 0.3s ease;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.columnpartener:hover {
  transform: scale(1.1);
}


/* Styles page partenaire les domaines partenaires */
.flip-box-wrap {
  display: flex;
  justify-content: space-around; /* To evenly distribute flip boxes */
  max-width: 1200px;
  margin: auto;
  margin-bottom: 50px;
  
}

.flip-box {
  width: 250px;
  height: 370px;
   padding: 5px;;
   margin-top: -150px;
}

.flip-box-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front, .flip-box-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#f0f0f0;
  box-shadow: rgba(245, 243, 243, 0.911) 0px 3px 6px, rgba(250, 247, 247, 0.89) 0px 3px 6px;
  border-radius: 25px;
}

.flip-box-back {
  transform: rotateY(180deg);
  background-color: #00b6fb;
  padding: 5px;
 
}
.flip-box-front-SI, .flip-box-front-devweb, .flip-box-front-IA, .flip-box-front-BD, .flip-box-front-CS  
{
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#f0f0f0;
  text-align: center;
  border-radius: 25px;
}
/* Styles background image pour la page partenaires et bloc domaine partenaire */
.inner-page-partners{
  background-color:#0a4a78; 
  background-size: cover;
  height: 400px;
  background-repeat: no-repeat;
}



/* Styles background image pour flip-box-wrap domaine partenaires */
.flip-box-front-SI{background-image: url('../../../public/domainparteners/si.PNG');
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  border-radius: 25px;}

  .flip-box-front-devweb{background-image: url('../../../public/domainparteners/webdev.PNG');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    border-radius: 25px;
  }
  .flip-box-front-IA{background-image: url('../../../public/domainparteners/IA.PNG');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    border-radius: 25px;
  }

  .flip-box-front-BD{background-image: url('../../../public/domainparteners/BigData.PNG');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    border-radius: 25px;
  }
  .flip-box-front-CS{background-image: url('../../../public/domainparteners/cybersecurity.PNG');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    border-radius: 25px;
  }
  

  
  @media screen and (max-width: 1024px) {
    .navbar-nav .nav-link.custom-btn {
      display: block;
    }
    .banner::after {
      background-position: calc(100% - 15px) 0px;
    }
    .banner::before, .banner::after{top: 105px!important;}
    .p-rc2 {right: 0.5%;top: 30%; }
    .title-breadcaumb-left{padding-left: 15px; padding-right: 15px;}
    .overlay-img--cubeLg1 { right: 4%!important; }
  }


  @media screen and (max-width: 768px) {
    .containerpartener{display: block!important;}
    .columncontacthome{margin: 10px!important;}
    .containerco{padding: 20px!important;}
    .bg-banner .title-breadcaumb-left{padding: 20px!important;}
    .bg-bread{height: 400px!important;}
    .flip-box-wrap{display: inline;}
    .flip-box{margin-top: 0px;}
    .video-play-bg{top: 300px;}
    .case-study .img-container{height: 440px!important;}
    .inner-page-banner h2{   font-size: 2.8rem;}
    .overlay-img--cubeLg1{   
      width: 8%!important;
      height: 15%!important;
      right: 0.9%!important;
      bottom: 50%!important;}
      .p-lc{left: -3.5%!important;}
      .p-rc {right: -3.5%!important;}
      .p-lu {left: -3.5%!important;}
      .p-rc2{right: 5.5%!important;}
      .overlay-img--cubeSm2 {
        width: 10%!important;
        height: 10%!important; }
        .inner-page-partners{height: 250px!important;}
        .flip-box-wrap{display: grid!important;}
        .overlaycarriere h2{font-size: 1.7rem;}
        .banner::before, .banner::after{top: 127px!important;}
        .banner::after{background-position: calc(100% - 0px) 0px;}
        .banner-content {
          padding: 80px 0 100px;
      }
    }




  @media screen and (max-width: 425px) {
    .containerpartener{display: block!important;}
    .columncontacthome{margin: 10px!important;}
    .containerco{padding: 20px!important;}
    .bg-banner .title-breadcaumb-left{padding: 20px!important;}
    .bg-bread{height: 400px!important;}
    .flip-box-wrap{display: inline;}
    .flip-box{margin-top: 0px;}
    .banner-content{padding: 9rem 0 6rem;}
    .section-subheading1 {font-size: 2rem; line-height: 3rem; font-weight: normal; color: #ffffff; width: 400px!important;}
    .video-play-bg{top: 0px!important;}
    .case-study .img-container{height: 230px!important;}
    .p-rc2{top:67%!important;}
    .title-breadcaumb-left p{font-size: 1.3rem;line-height: 2; }
    .title-breadcaumb-left h1{font-size: 2.7rem;}
    .title-breadcaumb-left h6{font-size: 1.5rem;}
    .overlay-img--cubeLg1 {width: 9%!important; height: 9%!important; bottom: 68%!important;}
    #service-tab-nav{width: 400px;}
    .nav-tabs .nav-item {width: 18rem; padding: 0.5rem 0.5rem; margin: 0.5rem 0.5rem; }
   .section-padding h5{margin-top: 15px;}
   .inner-page-partners{height: 70px!important;}
   .containercarriere{display: grid;}
   .banner::before, .banner::after{top: 75px!important;}
   .bg-banner .title-breadcaumb-left{padding: 10px!important;}
  }

  @media screen and (max-width: 375px) {
    #service-tab-nav{width: 350px;}
    .nav-tabs .nav-item{width: 15rem;}
    .p-rc2 {top: 75%!important;}
    .overlay-img--cubeSm2 { width: 12%!important; height: 6%!important; }
    .section-subheading1 {width: 350px!important;line-height: 2.7rem;}
    .banner::before, .banner::after {top: 130px !important;}
    .bg-banner .title-breadcaumb-left{padding: 10px!important;}
    }

  .navbar-nav .nav-item .nav-link:not(.custom-btn):hover{color: #065c94;}
  .columnpartener .fa-envelope{color:#065c94; width: 60px;}

  .columncontacthome {
    flex: 1;
    padding: 10px;
    margin: 10px;
    text-align: center;
    transition: transform 0.3s ease;
    background-color: #fff;
    margin-bottom: -100px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  .content-block.service-box{ box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
.bg-bread{
  background-image: linear-gradient(45deg, #26ABE0, #0A4A78);
  margin-top: 100px;
}
 .bg-banner {
  height: 300px;
  max-width: 1200px;
  margin:auto;
  
 }
 .bg-banner .title-breadcaumb-left{
  float: left;
  width: 70%;
  align-items: center;
  position: relative;
  padding-top: 50px;
  
 }
 .title-breadcaumb-left p{color: #fff; padding-bottom: 15px;}

 .bg-banner .imagebreadcrumb-right{
  float: right;
  width: 30%;
  align-items: center;
  margin: auto;
  padding-top: 35px;
  }

  .imagebreadcrumb-right img{float: right; border-radius: 50%;}

 

 .title-breadcaumb-left .button-header{border-radius: 3rem;
  padding: 14px 45px;
  position: relative;  color: #0A4A78;
  background-color: #fff;
}
.title-breadcaumb-left .button-header:hover{
  background-image: linear-gradient(45deg, #04266e, #0769be);
  color: #fff;
}

.modal-body .col-lg-6 h2{color: #000000;}

.titleco iframe{ width: 100%; height: 100%;}

.service-process-tab #service-tab h4{color: #000000;}
#parteners-home{margin-top:50px; margin-bottom:50px;}
#career-home{padding: 200px 50px;}
.portfolio {color:#000000;}

.black-header{color:#000000!important;}
.apropos{padding-left: 50px!important;}
.text-justify{text-align: justify;}
.title-breadcaumb-left .button-header{display: none;}
.overlay-img--cubeLg1 {
  width: 5%;
  height: 22%;
  background: #0A4A78;
  mix-blend-mode: multiply;
  right: 16.9%;
  bottom: 17.4%;
}

.overlay-img--cubeSm2 {
  width: 8%;
  height: 15%;
  background: #0A4A78;
  mix-blend-mode: multiply;
}
.p-rc2{
  right: 14.5%;
  top: 50%;
}
.icon {
  color: #c6ced9!important;
  font-size: 2.5rem!important;
}
.service-nav{padding-left: 50px!important;}

.modal-body .text-content p{text-align: justify;}

.para-text {  width: 681px;}
.contactpage .wrapperco {display: none!important;}
.px-3{padding-left: 4rem!important;}